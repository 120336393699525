<template>
  <div>
    <v-col>
      <v-list subheader two-line dense>
        <template v-for="item in appointments">
          <v-list-item :key="item.Id">
            <v-list-item-content>
              <v-list-item-title> {{ item.Date | moment }} {{ item.Time }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ item.AppointmentCategoryName }} {{ item.PersonnelName }} -
                {{ item.ClassroomName }}</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-action v-if="item.IsAttended != null"
              ><v-chip v-if="item.IsAttended" small color="success">Katıldı</v-chip>
              <v-chip v-else small color="error"> Katılmadı </v-chip>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="item.Id" class="mx-2"></v-divider>
        </template>
      </v-list>
    </v-col>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import moment from 'moment'

export default {
  name: 'StudentPastAppointmentsList',
  data() {
    return {
      appointments: []
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).format('DD.MM.YYYY') : ''
    }
  },
  created() {
    this.getStudentAppointments()
  },
  methods: {
    getStudentAppointments() {
      ApiService.setHeader()
      ApiService.post('api/AppointmentStudent/student-registered-appointments', { IsPast: true })
        .then((data) => {
          this.appointments = data.data
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    }
  }
}
</script>
