<template>
  <div>
    <v-col v-if="appointments.length > 0">
      <v-list subheader two-line dense>
        <template v-for="item in appointments">
          <v-list-item :key="item.Id" three-line>
            <v-list-item-content>
              <v-list-item-title> {{ item.Date | moment }} {{ item.Time }}</v-list-item-title>
              <v-list-item-subtitle>
               {{ item.CourseName }} - {{ item.AppointmentCategoryName }} - {{ item.PersonnelName }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-btn text color="primary" small @click="showInfo(item)">Detaylar</v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn v-if="item.MeetingLink != null" icon>
                <v-icon class="ml-1" @click="goToLink(item.MeetingLink)" color="blue lighten-1" dark
                >mdi-message-video
                </v-icon
                >
              </v-btn>
              <v-btn icon>
                <v-icon class="ml-1" @click="editAttachment(item)" color="green lighten-1" dark
                >mdi-file-document-outline
                </v-icon
                >
              </v-btn>
              <v-btn icon>
                <v-icon class="ml-1" @click="editItem(item)" color="red lighten-1" dark
                >mdi-delete-outline
                </v-icon
                >
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="item.Id" class="mx-2"></v-divider>
        </template>
      </v-list>
      <v-dialog persistent v-model="dialog" max-width="400px">
        <v-card>
          <v-toolbar flat>
            <v-row class="justify-content-between">
              <v-col class="col-4"> Randevu İptal</v-col>
              <v-col class="col-4"></v-col>
              <v-col class="col-4 text-right">
                <v-icon @click="dialog = false">mdi-close</v-icon>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-card-text>
            <strong>Öğretmen:</strong> {{ editedItem.PersonnelName }}
            <br/>
            <strong>Tarih ve Saat:</strong> {{ editedItem.Date | moment }} {{ editedItem.Time }}
            <br/>
            <strong> Mekan: </strong> {{ editedItem.ClassroomName }}
            <br/>
            <span v-if="editedItem.Description != null">
              <strong>Açıklama:</strong> {{ editedItem.Description }}
            </span>
            <v-textarea
              class="mt-3"
              dense
              hide-details
              v-model="editedItem.CancellationText"
              outlined
              rows="2"
              auto-grow
              label="İptal Nedeni"
            ></v-textarea>
          </v-card-text>
          <v-card-actions class="justify-content-center">
            <v-btn color="primary" @click="cancelAppointment"> RANDEVUYU İPTAL ET</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="infoDialog" max-width="400px">
        <v-card>
          <v-toolbar flat>
            <v-row class="justify-content-between">
              <v-col class="col-4"> Randevu Bilgileri</v-col>
              <v-col class="col-4"></v-col>
              <v-col class="col-4 text-right">
                <v-icon @click="close">mdi-close</v-icon>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-card-text>
            <strong>Ders:</strong> {{ editedItem.CourseName }}
            <br/>
            <strong>Öğretmen:</strong> {{ editedItem.PersonnelName }}
            <br/>
            <strong>Tarih ve Saat:</strong> {{ editedItem.Date | moment }} {{ editedItem.Time }}
            <br/>
            <strong> Mekan: </strong> {{ editedItem.ClassroomName }}
            <br/>
            <span v-if="editedItem.Description != null">
              <strong>Açıklama:</strong> {{ editedItem.Description }}
            </span>
            <br/>
            <span v-if="editedItem.SubjectName != null">
              <strong>Ünite - Konu:</strong> {{ editedItem.LessonUnitName }} - {{ editedItem.SubjectName }}
            </span>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="attachmentDialog" persistent max-width="400px">
        <v-card>
          <v-toolbar flat>
            <v-row class="justify-content-between">
              <v-col class="col-4"> Doküman Yükle</v-col>
              <v-col class="col-4"></v-col>
              <v-col class="col-4 text-right">
                <v-icon @click="attachmentDialog = false">mdi-close</v-icon>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-card-text>
            <div class="col">
              <v-file-input
                v-model="files"
                counter
                label="Dokümanlar"
                multiple
                placeholder="Doküman Yükle"
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                outlined
                :show-size="1000"
                style="max-width: 480px"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip v-if="index < 3" color="primary" dark label small>
                    {{ text }}
                  </v-chip>
                  <span
                    v-else-if="index === 2"
                    class="text-overline grey--text text--darken-3 mx-2"
                  >
                    +{{ files.length - 2 }} Dosya
                  </span>
                </template>
              </v-file-input>
            </div>
            <div class="text-center m-3">
              <b-button variant="primary" :disabled="loading" @click="prepareUpload"
              >Doküman Yükle
              </b-button
              >
            </div>
            <div class="row" v-if="attachments.length > 0 && !loading">
              <div class="col">
                <v-divider class="mx-4"></v-divider>
                <h4 style="color: green" class="mt-3">Yüklediğim Dokümanlar</h4>
                <FileAgent style="max-width: 480px" :files="attachments"></FileAgent>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col v-else>
      <v-alert type="info"> Aktif randevunuz bulunmamaktadır.</v-alert>
    </v-col>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import moment from 'moment'
import CompressionService from '@/core/services/image-compression.service'
import Swal from 'sweetalert2'
import FileAgent from '@/view/components/FileAgent'

export default {
  name: 'StudentAppointmentsList',
  components: { FileAgent },

  data() {
    return {
      appointments: [],
      dialog: false,
      infoDialog: false,
      editedItem: {},
      attachmentDialog: false,
      files: [],
      loading: false,
      attachments: []
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).format('DD.MM.YYYY') : ''
    }
  },
  created() {
    this.getStudentAppointments()
  },
  methods: {
    getStudentAppointments() {
      ApiService.setHeader()
      ApiService.post('api/AppointmentStudent/student-registered-appointments', {})
      .then((data) => {
        this.appointments = data.data
      })
      .catch(({ response }) => {
        ApiService.showError(response)
      })
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    
    showInfo(item) {
      this.editedItem = Object.assign({}, item)
      this.infoDialog = true
    },

    editAttachment(item) {
      this.editedItem = Object.assign({}, item)
      this.getAppointmentAttachments()
      this.attachmentDialog = true
    },

    close() {
      this.dialog = false
      this.infoDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {})
      })
    },

    cancelAppointment() {
      let model = {
        AppointmentTemplateId: this.editedItem.Id,
        CancellationText: this.editedItem.CancellationText
      }
      ApiService.setHeader()
      ApiService.put('api/AppointmentStudent/cancel-appointment', model)
      .then(() => {
        this.close()
        this.getStudentAppointments()
      })
      .catch(({ response }) => {
        ApiService.showError(response)
      })
    },

    goToLink(link) {
      window.open(link, '_blank')
    },

    async prepareUpload() {
      this.loading = true
      const fileImages = this.files.filter((s) => s.type.includes('image'))
      await CompressionService.compressMultipleImages(fileImages)
      .then((data) => {
        this.files = data.concat(this.files.filter((s) => !s.type.includes('image')))
        this.uploadAnswers()
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: 'bottom',
          title: err,
          icon: 'error',
          showConfirmButton: false,
          timer: 2000
        })
        this.loading = false
      })
    },

    uploadAnswers() {
      if (this.files.length > 0) {
        const formData = new FormData()
        for (const file of this.files) {
          formData.append('files', file, file.name)
        }
        ApiService.setHeader()
        ApiService.postwithconfig(
          `api/AppointmentAttachment/upload-file/` + this.editedItem.Id,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        .then((data) => {
          this.files = []
          this.getAppointmentAttachments()
          this.loading = false
          if (data.data) {
            Swal.fire({
              toast: true,
              position: 'bottom',
              title: 'Başarılı',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
            })
          }
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          this.loading = false
        })
      } else {
        Swal.fire({
          toast: true,
          position: 'center',
          title: 'Cevap eklemediniz',
          icon: 'warning',
          showConfirmButton: false,
          timer: 2000
        })
        this.loading = false
      }
    },

    getAppointmentAttachments() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/AppointmentAttachment', {
        AppointmentTemplateId: this.editedItem.Id
      })
      .then((data) => {
        this.attachments = data.data
        this.loading = false
      })
      .catch(({ response }) => {
        ApiService.showError(response)
      })
    }
  }
}
</script>
