var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-col",
        [
          _c(
            "v-list",
            { attrs: { subheader: "", "two-line": "", dense: "" } },
            [
              _vm._l(_vm.appointments, function (item) {
                return [
                  _c(
                    "v-list-item",
                    { key: item.Id },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("moment")(item.Date)) +
                                " " +
                                _vm._s(item.Time)
                            ),
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              " " +
                                _vm._s(item.AppointmentCategoryName) +
                                " " +
                                _vm._s(item.PersonnelName) +
                                " - " +
                                _vm._s(item.ClassroomName)
                            ),
                          ]),
                        ],
                        1
                      ),
                      item.IsAttended != null
                        ? _c(
                            "v-list-item-action",
                            [
                              item.IsAttended
                                ? _c(
                                    "v-chip",
                                    { attrs: { small: "", color: "success" } },
                                    [_vm._v("Katıldı")]
                                  )
                                : _c(
                                    "v-chip",
                                    { attrs: { small: "", color: "error" } },
                                    [_vm._v(" Katılmadı ")]
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-divider", { key: item.Id, staticClass: "mx-2" }),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }