var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { "min-height": "500px" } },
    [
      _c(
        "v-tabs",
        {
          attrs: {
            grow: "",
            centered: "",
            "show-arrows": "",
            "background-color": "primary",
            dark: "",
          },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tab", { attrs: { href: "#tab-1" } }, [_vm._v(" Randevu Al ")]),
          _c("v-tab", { attrs: { href: "#tab-2" } }, [
            _vm._v(" Randevularım "),
          ]),
          _c("v-tab", { attrs: { href: "#tab-3" } }, [
            _vm._v(" Randevu İste "),
          ]),
          _c("v-tab", { attrs: { href: "#tab-4" } }, [
            _vm._v(" Geçmiş Randevularım "),
          ]),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          attrs: { touchless: "" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            { attrs: { value: "tab-1" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("AppointmentList", {
                        on: {
                          updateTemplateEvent: function ($event) {
                            return _vm.templateUpdated()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { value: "tab-2" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [_c("StudentAppointmentsList", { key: _vm.componentKey })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { value: "tab-3" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [_c("v-card-text", [_c("AppointmentRequests")], 1)],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { value: "tab-4" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [_c("v-card-text", [_c("StudentPastAppointmentsList")], 1)],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }