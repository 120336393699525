<template>
  <v-card min-height="500px">
    <v-tabs grow centered show-arrows v-model="tab" background-color="primary" dark>
      <v-tab href="#tab-1"> Randevu Al </v-tab>
      <v-tab href="#tab-2"> Randevularım </v-tab>
      <v-tab href="#tab-3"> Randevu İste </v-tab>
      <v-tab href="#tab-4"> Geçmiş Randevularım </v-tab>
    </v-tabs>
    <v-tabs-items touchless v-model="tab">
      <v-tab-item :value="'tab-1'">
        <v-card flat>
          <v-card-text
            ><AppointmentList @updateTemplateEvent="templateUpdated()"></AppointmentList
          ></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="'tab-2'">
        <v-card flat>
          <v-card-text
            ><StudentAppointmentsList :key="componentKey"></StudentAppointmentsList
          ></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="'tab-3'">
        <v-card flat>
          <v-card-text><AppointmentRequests></AppointmentRequests></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="'tab-4'">
        <v-card flat>
          <v-card-text><StudentPastAppointmentsList></StudentPastAppointmentsList></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import AppointmentList from '@/view/pages/appointment/AppointmentList'
import StudentAppointmentsList from '@/view/pages/appointment/StudentAppointmentsList'
import StudentPastAppointmentsList from '@/view/pages/appointment/StudentPastAppointmentsList'
import AppointmentRequests from '@/view/pages/appointment/AppointmentRequests'

export default {
  name: 'appointment-component',
  components: {
    StudentPastAppointmentsList,
    StudentAppointmentsList,
    AppointmentList,
    AppointmentRequests
  },
  data() {
    return {
      tab: null,
      componentKey: 0,
      rows: []
    }
  },
  methods: {
    templateUpdated() {
      this.componentKey += 1
    }
  }
}
</script>
