var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.appointments.length > 0
        ? _c(
            "v-col",
            [
              _c(
                "v-list",
                { attrs: { subheader: "", "two-line": "", dense: "" } },
                [
                  _vm._l(_vm.appointments, function (item) {
                    return [
                      _c(
                        "v-list-item",
                        { key: item.Id, attrs: { "three-line": "" } },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("moment")(item.Date)) +
                                    " " +
                                    _vm._s(item.Time)
                                ),
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(item.CourseName) +
                                    " - " +
                                    _vm._s(item.AppointmentCategoryName) +
                                    " - " +
                                    _vm._s(item.PersonnelName) +
                                    " "
                                ),
                              ]),
                              _c(
                                "v-list-item-subtitle",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        color: "primary",
                                        small: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showInfo(item)
                                        },
                                      },
                                    },
                                    [_vm._v("Detaylar")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-action",
                            [
                              item.MeetingLink != null
                                ? _c(
                                    "v-btn",
                                    { attrs: { icon: "" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-1",
                                          attrs: {
                                            color: "blue lighten-1",
                                            dark: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToLink(
                                                item.MeetingLink
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("mdi-message-video ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-btn",
                                { attrs: { icon: "" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-1",
                                      attrs: {
                                        color: "green lighten-1",
                                        dark: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editAttachment(item)
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-file-document-outline ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                { attrs: { icon: "" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-1",
                                      attrs: {
                                        color: "red lighten-1",
                                        dark: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-delete-outline ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", { key: item.Id, staticClass: "mx-2" }),
                    ]
                  }),
                ],
                2
              ),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "400px" },
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "justify-content-between" },
                            [
                              _c("v-col", { staticClass: "col-4" }, [
                                _vm._v(" Randevu İptal"),
                              ]),
                              _c("v-col", { staticClass: "col-4" }),
                              _c(
                                "v-col",
                                { staticClass: "col-4 text-right" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.dialog = false
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c("strong", [_vm._v("Öğretmen:")]),
                          _vm._v(
                            " " + _vm._s(_vm.editedItem.PersonnelName) + " "
                          ),
                          _c("br"),
                          _c("strong", [_vm._v("Tarih ve Saat:")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("moment")(_vm.editedItem.Date)) +
                              " " +
                              _vm._s(_vm.editedItem.Time) +
                              " "
                          ),
                          _c("br"),
                          _c("strong", [_vm._v(" Mekan: ")]),
                          _vm._v(
                            " " + _vm._s(_vm.editedItem.ClassroomName) + " "
                          ),
                          _c("br"),
                          _vm.editedItem.Description != null
                            ? _c("span", [
                                _c("strong", [_vm._v("Açıklama:")]),
                                _vm._v(
                                  " " + _vm._s(_vm.editedItem.Description) + " "
                                ),
                              ])
                            : _vm._e(),
                          _c("v-textarea", {
                            staticClass: "mt-3",
                            attrs: {
                              dense: "",
                              "hide-details": "",
                              outlined: "",
                              rows: "2",
                              "auto-grow": "",
                              label: "İptal Nedeni",
                            },
                            model: {
                              value: _vm.editedItem.CancellationText,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editedItem,
                                  "CancellationText",
                                  $$v
                                )
                              },
                              expression: "editedItem.CancellationText",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "justify-content-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.cancelAppointment },
                            },
                            [_vm._v(" RANDEVUYU İPTAL ET")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "400px" },
                  model: {
                    value: _vm.infoDialog,
                    callback: function ($$v) {
                      _vm.infoDialog = $$v
                    },
                    expression: "infoDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "justify-content-between" },
                            [
                              _c("v-col", { staticClass: "col-4" }, [
                                _vm._v(" Randevu Bilgileri"),
                              ]),
                              _c("v-col", { staticClass: "col-4" }),
                              _c(
                                "v-col",
                                { staticClass: "col-4 text-right" },
                                [
                                  _c("v-icon", { on: { click: _vm.close } }, [
                                    _vm._v("mdi-close"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-card-text", [
                        _c("strong", [_vm._v("Ders:")]),
                        _vm._v(" " + _vm._s(_vm.editedItem.CourseName) + " "),
                        _c("br"),
                        _c("strong", [_vm._v("Öğretmen:")]),
                        _vm._v(
                          " " + _vm._s(_vm.editedItem.PersonnelName) + " "
                        ),
                        _c("br"),
                        _c("strong", [_vm._v("Tarih ve Saat:")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("moment")(_vm.editedItem.Date)) +
                            " " +
                            _vm._s(_vm.editedItem.Time) +
                            " "
                        ),
                        _c("br"),
                        _c("strong", [_vm._v(" Mekan: ")]),
                        _vm._v(
                          " " + _vm._s(_vm.editedItem.ClassroomName) + " "
                        ),
                        _c("br"),
                        _vm.editedItem.Description != null
                          ? _c("span", [
                              _c("strong", [_vm._v("Açıklama:")]),
                              _vm._v(
                                " " + _vm._s(_vm.editedItem.Description) + " "
                              ),
                            ])
                          : _vm._e(),
                        _c("br"),
                        _vm.editedItem.SubjectName != null
                          ? _c("span", [
                              _c("strong", [_vm._v("Ünite - Konu:")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.editedItem.LessonUnitName) +
                                  " - " +
                                  _vm._s(_vm.editedItem.SubjectName) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "400px" },
                  model: {
                    value: _vm.attachmentDialog,
                    callback: function ($$v) {
                      _vm.attachmentDialog = $$v
                    },
                    expression: "attachmentDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "justify-content-between" },
                            [
                              _c("v-col", { staticClass: "col-4" }, [
                                _vm._v(" Doküman Yükle"),
                              ]),
                              _c("v-col", { staticClass: "col-4" }),
                              _c(
                                "v-col",
                                { staticClass: "col-4 text-right" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.attachmentDialog = false
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-card-text", [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c("v-file-input", {
                              staticStyle: { "max-width": "480px" },
                              attrs: {
                                counter: "",
                                label: "Dokümanlar",
                                multiple: "",
                                placeholder: "Doküman Yükle",
                                "prepend-icon": "",
                                "prepend-inner-icon": "mdi-paperclip",
                                outlined: "",
                                "show-size": 1000,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function ({ index, text }) {
                                      return [
                                        index < 3
                                          ? _c(
                                              "v-chip",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  dark: "",
                                                  label: "",
                                                  small: "",
                                                },
                                              },
                                              [_vm._v(" " + _vm._s(text) + " ")]
                                            )
                                          : index === 2
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-overline grey--text text--darken-3 mx-2",
                                              },
                                              [
                                                _vm._v(
                                                  " +" +
                                                    _vm._s(
                                                      _vm.files.length - 2
                                                    ) +
                                                    " Dosya "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3076145010
                              ),
                              model: {
                                value: _vm.files,
                                callback: function ($$v) {
                                  _vm.files = $$v
                                },
                                expression: "files",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "text-center m-3" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: "primary",
                                  disabled: _vm.loading,
                                },
                                on: { click: _vm.prepareUpload },
                              },
                              [_vm._v("Doküman Yükle ")]
                            ),
                          ],
                          1
                        ),
                        _vm.attachments.length > 0 && !_vm.loading
                          ? _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c("v-divider", { staticClass: "mx-4" }),
                                  _c(
                                    "h4",
                                    {
                                      staticClass: "mt-3",
                                      staticStyle: { color: "green" },
                                    },
                                    [_vm._v("Yüklediğim Dokümanlar")]
                                  ),
                                  _c("FileAgent", {
                                    staticStyle: { "max-width": "480px" },
                                    attrs: { files: _vm.attachments },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-col",
            [
              _c("v-alert", { attrs: { type: "info" } }, [
                _vm._v(" Aktif randevunuz bulunmamaktadır."),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }